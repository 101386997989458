<div class="overlay-inner-wrapper overlay-fullscreen">
  <div id="inspektionWrapper">
    <header class="header-2">
      <div class="branding">
        <span class="title">{{titel}}</span>
      </div>
      <clr-spinner *ngIf="isLoading"></clr-spinner>
      <div class="header-actions">
        <a href="javascript://" class="nav-link nav-icon" style="margin-right: 20px ;">
          <clr-icon shape="times" (click)="close()"></clr-icon>
        </a>
      </div>
    </header>

    <div class="clr-row nxm">
      <div id="overlaySwitcher" class="clr-col-4 nxm" style="height: 92vh;max-height: 92vh;">
        <div class="clr-row" style="width: 100%;height: 80vh;max-height: 80vh;">
          <clr-accordion style="width: 100%;">

            <clr-accordion-panel>
              <clr-accordion-title>{{'Datenbank' | mrTranslate}}</clr-accordion-title>
              <clr-accordion-content *clrIfExpanded>
                <button class="btn" style="max-width:98%;width:100%" id="btnInspRestore" (click)="setView('createTestDatabase')" title="">{{'Testdatenbank erstellen' | mrTranslate}}</button>
                <button class="btn" *ngIf="authservice.token.rights.dev == true" style="max-width:98%;width:100%" id="btnDeleteTestDB" (click)="setView('deleteTestDatabase')" title="">{{'Testdatenbank löschen' | mrTranslate}}</button>
                <button class="btn" *ngIf="authservice.token.rights.dev == true" style="max-width:98%;width:100%" id="btnRefreshDBConDictonary" (click)="setView('refreshDBConDictonary')" title="">{{'Connectionstring-Dictonary neu aufbauen' | mrTranslate}}</button>
              </clr-accordion-content>
            </clr-accordion-panel>

            <clr-accordion-panel>
              <clr-accordion-title>{{'Inspektion' | mrTranslate}}</clr-accordion-title>
              <clr-accordion-content *clrIfExpanded>
                <button class="btn" style="max-width:98%;width:100%" id="btnInspRestore" (click)="setView('inspRestore')" title="">{{'Prüfung wiederherstellen' | mrTranslate}}</button>
                <button class="btn" style="max-width:98%;width:100%" id="btnInspFullDelete" (click)="setView('inspFullDelete')" title="">{{'Prüfung endgültig löschen' | mrTranslate}}</button>
              </clr-accordion-content>
            </clr-accordion-panel>
            <clr-accordion-panel>
              <clr-accordion-title>{{'Stammdaten' | mrTranslate}}</clr-accordion-title>
              <clr-accordion-content *clrIfExpanded>
                <button class="btn" style="max-width:98%;width:100%" id="btnMasterRestore" (click)="setView('masterRestore')" title="">{{'Stammdaten wiederherstellen' | mrTranslate}}</button>
                <button class="btn" style="max-width:98%;width:100%" id="btnMasterDelete" (click)="setView('masterDelete')" title="">{{'Stammdaten endgültig löschen' | mrTranslate}}</button>
                <button class="btn" style="max-width:98%;width:100%" id="btnMasterDeleteMultiple" (click)="setView('masterDeleteMultiple')" title="">{{'Stammdaten mehrfach endgültig löschen' | mrTranslate}}</button>
                <button class="btn" style="max-width:98%;width:100%" id="btnMasterArchive" (click)="setView('masterArchive')" title="">{{'Archivierte Objekte anzeigen' | mrTranslate}}</button>
                <button class="btn" style="max-width:98%;width:100%" id="btnMasterCopy" (click)="setView('masterCopy')" title="">{{'Stammdaten kopieren' | mrTranslate}}</button>
                <button class="btn" style="max-width:98%;width:100%" id="btnMasterUnlock" (click)="setView('masterUnlock')" title="">{{'Stammdaten Replikatsperre entsperren' | mrTranslate}}</button>
              </clr-accordion-content>
            </clr-accordion-panel>
            <clr-accordion-panel>
              <clr-accordion-title>{{'Gleise' | mrTranslate}}</clr-accordion-title>
              <clr-accordion-content *clrIfExpanded>
                <button class="btn" style="max-width:98%;width:100%" (click)="setView('lineConnect')">{{'Gleise verbinden' | mrTranslate}}</button><br>
                <button class="btn" style="max-width:98%;width:100%" (click)="setView('lineCut')">{{'Gleise trennen' | mrTranslate}}</button><br>
                <button class="btn" style="max-width:98%;width:100%" (click)="setView('lineTurn')">{{'Gleis drehen' | mrTranslate}}</button>
                <button class="btn" style="max-width:98%;width:100%" (click)="setView('moveStart')" title="">{{'Startposition verschieben' | mrTranslate}}</button><br>
              </clr-accordion-content>
            </clr-accordion-panel>

            <clr-accordion-panel>
              <clr-accordion-title>{{'Zuordnungen' | mrTranslate}}</clr-accordion-title>
              <clr-accordion-content *clrIfExpanded>
                <button class="btn" style="max-width:98%;width:100%" (click)="setView('assignInsp')" title="">{{'Prüfzuordnungen wieder herstellen' | mrTranslate}}</button>
                <button class="btn" style="max-width:98%;width:100%" (click)="setView('assignMess')" title="">{{'Zuordnungen letzte Messung wieder herstellen' | mrTranslate}}</button>
                <button class="btn" style="max-width:98%;width:100%" (click)="setView('assignError')" title="">{{'Fehlerklassenzuordnung wiederherstellen' | mrTranslate}}</button>
                <button class="btn" style="max-width:98%;width:100%" (click)="setView('sortOrder')" title="">{{'Auftrag umsortieren' | mrTranslate}}</button>
              </clr-accordion-content>
            </clr-accordion-panel>

            <clr-accordion-panel>
              <clr-accordion-title>{{'Import/Export' | mrTranslate}}</clr-accordion-title>
              <clr-accordion-content style="width: 500px;" *clrIfExpanded>
                <button class="btn" style="max-width:98%;width:100%" (click)="setView('xlSpez')">{{'Export Spezifikationen' | mrTranslate}}</button><br>
                <button class="btn" style="max-width:98%;width:100%" (click)="setView('xlBauw')">{{'Bauwerke' | mrTranslate}}</button><br>
                <button class="btn" style="max-width:98%;width:100%" (click)="setView('xlHerst')">{{'Herstellungsdaten' | mrTranslate}}</button><br>
                <button class="btn" style="max-width:98%;width:100%" (click)="setView('xlStamm')">{{'Vorgabe für Stammdatenimport erstellen' | mrTranslate}}</button>
                <button class="btn" style="max-width:98%;width:100%" (click)="setView('xlStammImp')">{{'Stammdatenliste aus Excel importieren' | mrTranslate}}</button>
                <button class="btn" style="max-width:98%;width:100%" (click)="setView('xlStammExp')">{{'Stammdaten zum Bearbeiten nach Excel exportieren' | mrTranslate}}</button>
                <button class="btn" style="max-width:98%;width:100%" (click)="setView('xlStammEditImp')">{{'Bearbeitete Stammdatenliste aus Excel einlesen' | mrTranslate}}</button>
                <button class="btn" style="max-width:98%;width:100%" (click)="setView('xlSpezExp')">{{'Spezifikationen zum Bearbeiten nach Excel exportieren' | mrTranslate}}</button>
                <button class="btn" style="max-width:98%;width:100%" (click)="setView('xlSpezImp')">{{'Bearbeitete Liste mit Spezifikationen aus Excel einlesen' | mrTranslate}}</button>
                <hr/>
                <button class="btn" style="max-width:98%;width:100%" (click)="setView('QRCode')">{{'QR-Code Labels erstellen' | mrTranslate}}</button>
              </clr-accordion-content>
            </clr-accordion-panel>

            <clr-accordion-panel>
              <clr-accordion-title>{{'KAV' | mrTranslate}}</clr-accordion-title>
              <clr-accordion-content *clrIfExpanded>
                <button class="btn" style="max-width:98%;width:100%" (click)="setView('kav_calculate')" title="">{{'KAV berechnen' | mrTranslate}}</button>
              </clr-accordion-content>
            </clr-accordion-panel>




          </clr-accordion>
        </div>
      </div>

      <div class="clr-col-8 nxm" style="height: 90vh;max-height: 90vh;">

        <div *ngIf="action != ''">
          <div *ngIf="action == 'inspRestore'">
            <ng-container *ngTemplateOutlet='tableLeft'></ng-container>
            <div style="justify-content: center" class="clr-row">
              <button class=" btn" (click)="startAction()">{{'Prüfung wiederherstellen' | mrTranslate}}</button><br>
            </div>
          </div>
          <div *ngIf="action =='inspFullDelete'">
            <ng-container *ngTemplateOutlet='tableLeft'></ng-container>
            <div style="justify-content: center" class="clr-row">
              <button class=" btn" (click)="startAction()">{{'Prüfung löschen' | mrTranslate}}</button><br>
            </div>
          </div>
          <div *ngIf="action =='masterRestore'">
            <ng-container *ngTemplateOutlet='tableLeft'></ng-container>
            <div style="justify-content: center" class="clr-row">
              <button class=" btn" (click)="startAction()">{{'Stammdaten wiederherstellen' | mrTranslate}}</button><br>
            </div>
          </div>
          <div *ngIf="action == 'masterDelete'">
            <ng-container *ngTemplateOutlet='tableLeft'></ng-container>
            <div style="justify-content: center" class="clr-row">
              <button class=" btn" (click)="startAction()">{{'Stammdaten löschen' | mrTranslate}}</button><br>
            </div>
          </div>
          <div *ngIf="action == 'masterDeleteMultiple'">
            <ng-container *ngTemplateOutlet='tableLeftMulti'></ng-container>
            <div style="justify-content: center" class="clr-row">
              <button class=" btn" (click)="startAction()">{{'Stammdaten löschen' | mrTranslate}}</button><br>
            </div>
          </div>
          <div *ngIf="action == 'masterUnlock'">
            <ng-container *ngTemplateOutlet='tableLeftMulti'></ng-container>
            <div style="justify-content: center" class="clr-row">
              <button class=" btn" (click)="startAction()">{{'Stammdaten entsperren' | mrTranslate}}</button><br>
            </div>
          </div>
          <div *ngIf="action == 'masterArchive'">
            <div [ngStyle]="{width: !showdetailview || hidedetailview ? '100%' : sidenavenlarged ? '0%' : split50 ? '27%' : '51%'}">
              <ng-container *ngTemplateOutlet='tableLeft'></ng-container>
            </div>
          </div>
          <div *ngIf="action == 'masterCopy'">
            <ng-container *ngTemplateOutlet='tableLeft'></ng-container>
            <div style="justify-content: center" class="clr-row">
              <label>{{'Bezeichnung' | mrTranslate}}: </label>
              <input type="text" id="CopyBezeichnung"/>
            </div>
            <br>
            <div style="justify-content: center" class="clr-row">
              <button class=" btn" (click)="startAction()">{{'Objekt kopieren' | mrTranslate}}</button><br>
            </div>
          </div>
          <div *ngIf="action =='moveStart'">
            <ng-container *ngTemplateOutlet='tableLeft'></ng-container>
            <div style="justify-content: center" class="clr-row">
              <div style="width: 350px; height:150px;border: 1px solid black;padding: 15px;margin: 10px;">
                <div class="container">
                  <label>{{'Startmeter alt' | mrTranslate}}</label>
                  <input type="number" disabled="true" id="OldStart" value={{movePos.OldStart}} (change)="movePos.OldStart = $event.target.value;"/>
                  <label>{{'Startmeter neu' | mrTranslate}} </label>
                  <input type="number" id="NewStart" value={{movePos.NewStart}} (change)="movePos.NewStart = $event.target.value;" />
                  <!-- <label>{{'Verschiebung' | mrTranslate}}</label>
                  <input type="text" id="Move" (change)="movePos.Move = $event.target.value;"/> -->
                  <label>{{'absteigend' | mrTranslate}}</label>
                  <input type="checkbox" clrToggle name="options" value="false" id="Descending" (change)="movePos.Descending = $event.checked" />
                </div>
              </div>
            </div>
            <div style="justify-content: center" class="clr-row">
              <button class=" btn" (click)="startAction()">{{'Startposition versetzen' | mrTranslate}}</button><br>
            </div>
          </div>
          <div *ngIf="action == 'lineConnect'">
            <div class="clr-row">
              <ng-container *ngTemplateOutlet='tableLeft'></ng-container>
              <ng-container *ngTemplateOutlet='tableRight'></ng-container>
            </div>
            <div style="justify-content: center" class="clr-row">
              <button class=" btn" (click)="startAction()">{{'Verbinden' | mrTranslate}}</button><br>
            </div>
            <div style="justify-content: center" class="clr-row">
              <label>{{info}}</label><br>
            </div>
            <div style="justify-content: center" class="clr-row">
              <clr-progress-bar [clrValue]="pcnt" clrLabeled style="margin-top: 10px; max-width: 600px;"></clr-progress-bar>
            </div>
          </div>
          <div *ngIf="action == 'lineCut'" style="max-height: 90vh;">
            <ng-container *ngTemplateOutlet='tableLeft'></ng-container>
            <div style="justify-content: center" class="clr-row">
              <div style="width: 600px; height:430px;border: 1px solid black;padding: 15px;margin: 10px;">
                <div class="container">
                  <label>{{'Trennposition (in m)' | mrTranslate}}</label>
                  <input type="number" id="TrennPos" value="{{cutVals.TrennPos}}" (change)="cutVals.TrennPos = $event.target.value; valuesChange()"/>
                  <label>{{'Neue Startposition' | mrTranslate}} </label>
                  <input type="number" id="NeuStartPos" value="{{cutVals.NeuStartPos}}" (change)="cutVals.NeuStartPos = $event.target.value; valuesChange()" />
                  <label>{{'Neuer Startpunkt' | mrTranslate}}</label>
                  <input type="text" id="NeuStartPunkt" (change)="cutVals.NeuStartPunkt = $event.target.value; selectionChanged()"/>
                  <label>{{'Neue Bezeichnung' | mrTranslate}} </label>
                  <input type="text" id="NeuBez"(change)="cutVals.NeuBez = $event.target.value; selectionChanged()"/>
                  <label>{{'Neuer Messfile' | mrTranslate}}</label>
                  <input type="text" id="NeuMess" (change)="cutVals.NeuMess = $event.target.value; selectionChanged()"/>
                </div>
                <br>
                <div style="width: 590px; margin-left: -11px; height: 200px; padding: 10px; border: 1px solid black;">
                  <div class="innercontainer">
                    <label>{{'Startpunkt' | mrTranslate}}</label>
                    <label>{{cutL.Startpunkt}}</label>
                    <label>{{'Startpunkt' | mrTranslate}}</label>
                    <label>{{cutR.Startpunkt}}</label>
                    <label>{{'Endpunkt' | mrTranslate}}</label>
                    <label>{{cutL.Endpunkt}}</label>
                    <label>{{'Endpunkt' | mrTranslate}}</label>
                    <label>{{cutR.Endpunkt}}</label>
                    <label>{{'Bezeichnung' | mrTranslate}}</label>
                    <label>{{cutL.Bezeichnung}}</label>
                    <label>{{'Bezeichnung' | mrTranslate}}</label>
                    <label>{{cutR.Bezeichnung}}</label>
                    <label>{{'Startposition' | mrTranslate}}</label>
                    <label>{{cutL.Startposition}}</label>
                    <label>{{'Startposition' | mrTranslate}}</label>
                    <label>{{cutR.Startposition}}</label>
                    <label>{{'Endposition' | mrTranslate}}</label>
                    <label>{{cutL.Endposition}}</label>
                    <label>{{'Endposition' | mrTranslate}}</label>
                    <label>{{cutR.Endposition}}</label>
                    <label>{{'Messfile' | mrTranslate}}</label>
                    <label>{{cutL.Messfile}}</label>
                    <label>{{'Messfile' | mrTranslate}}</label>
                    <label>{{cutR.Messfile}}</label>
                    <label>{{'Gleislänge' | mrTranslate}}</label>
                    <label>{{cutL.Gleislaenge}}</label>
                    <label>{{'Gleislänge' | mrTranslate}}</label>
                    <label>{{cutR.Gleislaenge}}</label>
                  </div>
                </div>
              </div>

            </div>
            <div style="justify-content: center" class="clr-row">
              <button class=" btn" (click)="startAction()">{{'Gleis trennen' | mrTranslate}}</button><br>
            </div>
            <div style="justify-content: center" class="clr-row">
              <label>{{info}}</label><br>
            </div>
            <div style="justify-content: center" class="clr-row">
              <clr-progress-bar [clrValue]="pcnt" clrLabeled style="margin-top: 10px; max-width: 600px;"></clr-progress-bar>
            </div>
          </div>

          <div *ngIf="action == 'lineTurn'">
            <ng-container *ngTemplateOutlet='tableLeft'></ng-container>
            <div style="justify-content: center" class="clr-row">
              <button class=" btn" (click)="startAction()">{{'Stammdaten auswählen' | mrTranslate}}</button><br>
            </div>
          </div>
          <div *ngIf="action == 'assignInsp' || action == 'assignMess' || action == 'assignError' || action == 'sortOrder' || action == 'kav_calculate'">
            <div style="justify-content: center" class="clr-row">
              <label style="font-size: 20px;">{{trTitel}}</label><br>
            </div>
            <div style="justify-content: center" class="clr-row">
              <button class=" btn btn-success" id="btnStart" (click)="startAction()" title="">{{'Starte Aktion' | mrTranslate}}</button>
            </div>
            <div style="justify-content: center" class="clr-row">
              <label>{{info}}</label><br>
            </div>
            <div style="justify-content: center" class="clr-row">
              <clr-progress-bar [clrValue]="pcnt" clrLabeled style="margin-top: 10px; max-width: 600px;"></clr-progress-bar>
            </div>
            <div style="width: 85wh; height:350px;border: 1px solid black;padding: 15px;margin: 10px;">
              <div style="width: 85wh;">
                <label style="height: 20px;">{{ "Informationen" | mrTranslate }}</label>
                <textarea id="bemerkung" name="bemerkung" rows="10" style="width: 100%; height: 300px;"></textarea>
              </div>
            </div>
          </div>

          <div *ngIf=" action == 'refreshDBConDictonary' ||  action == 'deleteTestDatabase'">
            <div style="justify-content: center" class="clr-row">
              <label style="font-size: 20px;">{{trTitel}}</label><br>
            </div>
            <div style="justify-content: center" class="clr-row">
              <button class=" btn btn-success" id="btnStart" (click)="startAction()" title="">{{'Starte Aktion' | mrTranslate}}</button>
            </div>
          </div>

          <div *ngIf="action == 'xlBauw' || action == 'xlHerst'">
            <div style="justify-content: center" class="clr-row">
              <label style="font-size: 20px;">{{trTitel}}</label><br>
            </div>
            <div style="justify-content: center" class="clr-row">
              <button class=" btn btn-success" id="btnStart" (click)="startAction()" title="">{{'Starte Excel-Export' | mrTranslate}}</button>
            </div>
          </div>

          <div *ngIf="action == 'xlStammImp' || action == 'xlStammEditImp' || action == 'xlSpezImp'">
            <div style="justify-content: center" class="clr-row">
              <label style="font-size: 20px;">{{trTitel}}</label><br>
            </div>
            <!-- <div style="justify-content: center" class="clr-row">
              <button class=" btn btn-success" id="btnStart" (click)="startAction()" title="">{{'Starte Excel-Export' | mrTranslate}}</button>
            </div> -->
            <div style="justify-content: center" class="clr-row">
              <button class="btn btn-success" type="button" (click)="fileUpload.click()">
                <clr-icon shape="image-gallery"></clr-icon> {{'Datei einlesen' | mrTranslate}}
              </button>
            </div>
            <input type="file" id="fileUpload" #fileUpload name="fileUpload" accept="{{accept}}" style="display:none;"  (change)="startAction($event)"/>
            <div style="justify-content: center" class="clr-row">
              <clr-progress-bar [clrValue]="pcnt" clrLabeled style="margin-top: 10px; max-width: 600px;"></clr-progress-bar>
            </div>

            <div style="width: 85wh; height:350px;border: 1px solid black;padding: 15px;margin: 10px;">
              <div style="width: 85wh;">
                <label style="height: 20px;">{{ "Import-Informationen" | mrTranslate }}</label>
                <textarea id="bemerkung" name="bemerkung" rows="10" style="width: 100%; height: 300px;"></textarea>
              </div>
            </div>
          </div>

          <div *ngIf="action == 'xlSpez' || action == 'QRCode'">
            <div style="justify-content: center" class="clr-row">
              <label style="font-size: 20px;">{{trTitel}}</label><br>
            </div>
            <div style="justify-content: center;border-style: solid;border-width: thin;" class="clr-row nxm">
              <clr-tree [(clrTreeSelected)]="selTreeNote">
                <clr-tree-node *ngFor="let item of lstObjects?.rows" [(clrSelected)]="item.selected">
                  <button (click)="treeClick(item)" class="clr-treenode-link" routerLinkActive="active">
                    <clr-icon class="customIcon" [attr.shape]="'objekttyp' + item.SysIcon" size="28"></clr-icon>
                    {{ item.BEZEICHNUNG }}
                  </button>
                </clr-tree-node>
              </clr-tree>
            </div>
            <div style="justify-content: center;" class="clr-row">
              <div *ngIf="action == 'xlSpez'">
                <button class=" btn btn-success" id="btnStart" (click)="startAction()" title="">{{'Starte Excel-Export' | mrTranslate}}</button>
              </div>
              <div *ngIf="action == 'QRCode'">
                <button class=" btn btn-success" id="btnStart" (click)="startAction()" title="">{{'QR-Codes erstellen' | mrTranslate}}</button>
              </div>
            </div>
          </div>

          <div *ngIf="action == 'xlStamm' || action == 'xlStammExp' || action == 'xlSpezExp'">
            <div style="justify-content: center" class="clr-row">
              <label style="font-size: 20px;">{{trTitel}}</label><br>
            </div>
            <div style="justify-content: center;border-style: solid;border-width: thin;" class="clr-row nxm">
              <clr-tree [(clrTreeSelected)]="selTreeNote">
                <clr-tree-node *ngFor="let item of lstObjects.rows">
                  <button (click)="startAction(item)" class="clr-treenode-link" routerLinkActive="active">
                    <clr-icon class="customIcon" [attr.shape]="'objekttyp' + item.SysIcon" size="28"></clr-icon>
                    {{ item.BEZEICHNUNG }}
                  </button>
                </clr-tree-node>
              </clr-tree>
            </div>
          </div>


        </div>
      </div>
    </div>

    <ng-template #tableLeft>
      <div *ngIf="tblLeft">
        <div class="{{tableColSize}}" style="padding-left:0px; padding-right:20px;z-index: 1;" *ngIf="tblLeft">
          <clr-datagrid [(clrDgSingleSelected)]="selectedtabellenL" (clrDgSingleSelectedChange)="selectionChanged($event)" class="datagrid-compact" [ngStyle]="{'height': tableheight}">
            <clr-dg-column *ngFor="let column of tblLeft['columns']" [clrDgField]="column.id" [hidden]="column.id | hideIDColumns: ['bildpfad','lf','st','sp','rep','messreg bezirk']">{{
              column.id | titlecase | mrTranslate
              }}</clr-dg-column>
            <clr-dg-row *clrDgItems="let row of tblLeft['rows']" [clrDgItem]="row">
              <clr-dg-cell *ngFor="let cell of tblLeft['columns']" [innerHTML]="row[cell.id] | tablePrettyPrint" [hidden]="cell.id | hideIDColumns: ['bildpfad','lf','st','sp','rep','messreg bezirk']"></clr-dg-cell>
            </clr-dg-row>
            <clr-dg-footer>
              <clr-dg-pagination class="dg-pagination" #pagination [clrDgPageSize]="20">
                {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
                {{'von' | mrTranslate}} {{pagination.totalItems}} {{'Zeilen' | mrTranslate}}
              </clr-dg-pagination>
            </clr-dg-footer>
          </clr-datagrid>
        </div>
      </div>

    </ng-template>

    <ng-template #tableRight>
        <div class="clr-col-6" style="padding-left:0px; padding-right:20px;" *ngIf="tblRight">
          <clr-datagrid [(clrDgSingleSelected)]="selectedtabellenR" class="datagrid-compact"  [ngStyle]="{'height': tableheight}">
            <clr-dg-column *ngFor="let column of tblRight['columns']" [clrDgField]="column.id" [hidden]="column.id | hideIDColumns: ['bildpfad','lf','st','sp','rep','messreg bezirk']">{{
              column.id | titlecase | mrTranslate
              }}</clr-dg-column>
            <clr-dg-row *clrDgItems="let row of tblRight['rows']" [clrDgItem]="row">
              <clr-dg-cell *ngFor="let cell of tblRight['columns']" [innerHTML]="row[cell.id] | tablePrettyPrint" [hidden]="cell.id | hideIDColumns: ['bildpfad','lf','st','sp','rep','messreg bezirk']"></clr-dg-cell>
            </clr-dg-row>
            <clr-dg-footer>
              <clr-dg-pagination class="dg-pagination" #pagination [clrDgPageSize]="20">
                {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
                {{'von' | mrTranslate}} {{pagination.totalItems}} {{'Zeilen' | mrTranslate}}
              </clr-dg-pagination>
            </clr-dg-footer>
          </clr-datagrid>
        </div>
    </ng-template>


    <ng-template #tableLeftMulti>

      <div class="{{tableColSize}}" style="padding-left:0px; padding-right:20px;">
        <clr-datagrid [(clrDgSelected)]="selectedtabellenLM" (clrDgSelectedChange)="selectionChanged($event)" class="datagrid-compact"  [ngStyle]="{'height': tableheight}">
          <clr-dg-column *ngFor="let column of tblLeft['columns']" [clrDgField]="column.id" [hidden]="column.id | hideIDColumns: ['bildpfad']">{{
            column.id | titlecase | mrTranslate
            }}</clr-dg-column>
          <clr-dg-row *clrDgItems="let row of tblLeft['rows']" [clrDgItem]="row">
            <clr-dg-cell *ngFor="let cell of tblLeft['columns']" [innerHTML]="row[cell.id] | tablePrettyPrint" [hidden]="cell.id | hideIDColumns: ['bildpfad']"></clr-dg-cell>
          </clr-dg-row>
          <clr-dg-footer>
            <clr-dg-pagination class="dg-pagination" #pagination [clrDgPageSize]="20">
              {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
              {{'von' | mrTranslate}} {{pagination.totalItems}} {{'Zeilen' | mrTranslate}}
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
      </div>

  </ng-template>

  </div>

  <div #dvwrapper id="wrapperdetailview" *ngIf="showdetailview"
    [ngClass]="{ sidenavenlarged, split50, hidedetailview }">
    <app-detailview [ostammid]="_ostammid" [viewMode]=1 [expanded]="sidenavenlarged" [hidden]="hidedetailview" [split50]="split50" (enlarge)="enlargeSidenav($event)"
    (indexScrub)="handleScrub($event)" #detailView></app-detailview>
  </div>
</div>


<clr-modal [(clrModalOpen)]="showCreateTestDatabase" [clrModalClosable]="true" [clrModalSize]="'xl'">
  <h3 class="modal-title">{{'Testdatenbank erstellen' | mrTranslate}}</h3>
<div class="modal-body">
  <form clrForm clrLayout="vertical">
    <div class="clr-row">
      <div class="clr-col-12">
        <span>Bitte wählen Sie die Benutzer aus, die Zugriff auf die Testdatenbank erhalten sollen.</span>
      </div>
    </div>

    <div *ngIf="users != undefined">

      <div class="limit-height">
        <clr-datagrid class="datagrid-compact" *ngIf="users" [(clrDgSelected)]="selectedUser" [clrDgRowSelection]="true" (clrDgSelectedChange)="selectionStammChanged($event)">
          <clr-dg-column *ngFor="let column of users['columns']" [clrDgField]="column.id" [hidden]="column.id | hideIDColumns:['userimagebase64', 'eisenbahnbetriebsleiter (ebl)', 'anlagenverantwortlicher (alv)' , 'user_ebl' ,'user_alv','status','pwhash']">
            {{ column.id | titlecase | mrTranslate }} </clr-dg-column>

          <clr-dg-row *clrDgItems="let row of users['rows']" [clrDgItem]="row">
            <ng-container *ngFor="let cell of users['columns']" >
              <clr-dg-cell [hidden]="cell.id | hideIDColumns:['userimagebase64', 'eisenbahnbetriebsleiter (ebl)', 'anlagenverantwortlicher (alv)' , 'user_ebl' ,'user_alv','status','pwhash']"
                [innerHTML]="row[cell.id] | tablePrettyPrint"
                [title]="row[cell.id] | tablePrettyPrint | dotDotDot : 30">
              </clr-dg-cell>
            </ng-container>
          </clr-dg-row>

          <clr-dg-footer>
            <clr-dg-pagination class="dg-pagination" style="right:60px" #pagination [clrDgPageSize]="500">{{users['rows'].length}} {{'Zeilen' | mrTranslate}}</clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
      </div>
    </div>

    <div class="clr-row" >
      <div class="clr-col-12" *ngIf="doCreateTestDatabase">
        <clr-progress-bar [clrValue]="pcnt" clrLabeled style="margin-top: 10px; max-width: 85vw; "></clr-progress-bar>
      </div>
    </div>

    <div class="clr-row">
      <div class="clr-col-12" *ngIf="doCreateTestDatabase">
        <span style="margin-top: 10px;">{{text}}</span>
      </div>
    </div>

  </form>
  <br>
</div>
  <div class="modal-footer" *ngIf="!doCreateTestDatabase" >
    <button type="button" class="btn btn-primary" (click)="createTestDatabase()" [disabled]="selectedUser.length == 0">OK</button>
    <button type="button" class="btn" (click)="showCreateTestDatabase = false">Abbrechen</button>
  </div>
</clr-modal>
