import OlMap from 'ol/Map';
import OlView from 'ol/View';
import OlAttribution from 'ol/control/Attribution';
import OlXYZ from 'ol/source/XYZ';
import OlVectorLayer from 'ol/layer/Vector';
import OlVectorSource from 'ol/source/Vector';
import OlTileLayer from 'ol/layer/Tile';
import OlGeoJSON from 'ol/format/GeoJSON';
import OlStyle from 'ol/style/Style';
import OlStroke from 'ol/style/Stroke';
import { defaults } from 'ol/control';
import { fromLonLat, transform } from 'ol/proj';
import lineSliceAlong from '@turf/line-slice-along';
import { Feature } from 'ol';
import { environment } from 'src/environments/environment';

function previewMap(row: any, geo: any, map:any = undefined) {
    let layers = [new OlTileLayer({
        source: new OlXYZ({
            attributions: '© Google',
            url: 'https://mt{0-3}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}&hl=de',
            crossOrigin: 'anonymous'
        })
    })];
    let geolayer;
    let selectlayer;
    let curFeatlayer;
    if (geo) {
        selectlayer = new OlVectorLayer({
            source: new OlVectorSource({
                format: new OlGeoJSON(),
                loader: (extent, resolution, projection) => {                    
                    let featurewrap: any = {
                        type: "Feature",
                        geometry: geo,
                    };
                    let feature;
                    if (geo.type != 'Point') {
                        var von = row.von || row.Startmeter.value;
                        var bis = row.bis || row.Endmeter.value;
                        if ((bis - von) < 1) {
                            bis = parseFloat((von + 1));
                        }
                        von = parseFloat((von / 1000.0).toString());
                        bis = parseFloat((bis / 1000.0).toString());
                        let slicedFeature = lineSliceAlong(featurewrap, von, bis);
                        feature = selectlayer.getSource().getFormat().readFeature(slicedFeature, { featureProjection: "EPSG:3857" })
                    } else {
                        feature = selectlayer.getSource().getFormat().readFeature(featurewrap, { featureProjection: "EPSG:3857" })
                    }
                    selectlayer.getSource().addFeature(feature);
                }
            }),
            style: (feature) => {
                let color = 'red';
                let fk = row.fehlerklasse ? row.fehlerklasse : row.Fehlerklasse ? row.Fehlerklasse : undefined;
                switch (fk) {
                    case 1:
                        color = 'red';
                        break;
                    case 2:
                        color = 'yellow';
                        break;
                    case 3:
                        color = 'green';
                        break;
                    case 4:
                        color = 'blue';
                        break;
                    default:
                        color = 'red';
                        break;
                }
                return new OlStyle({
                    stroke: new OlStroke({
                        color: color,
                        width: 4
                    }),
                    zIndex: 0
                });
            }
        });
        geolayer = new OlVectorLayer({
            source: new OlVectorSource({
                format: new OlGeoJSON(),
                loader: (extent, resolution, projection) => {         
                    var xhr = new XMLHttpRequest();
                    let token = localStorage.getItem("jwt");
                    xhr.open("GET", environment.apiUrl + "/karte/topojson", true);
                    xhr.setRequestHeader("Authorization", "Bearer " + token);
                    var onError = () => {
                        geolayer.getSource().removeLoadedExtent(extent);
                    };
                    xhr.onerror = onError;
                    xhr.onload = () => {
                        if (xhr.status == 200) {
                            let src = geolayer.getSource();
                            setTimeout(() => {
                                let features = src.getFormat().readFeatures(xhr.responseText, {
                                    featureProjection: "EPSG:3857",
                                });
                                src.addFeatures(features);
                            });
                        } else {
                            onError();
                        }
                    };
                    xhr.send();
                }
                    // let featurewrap: any = {
                    //     type: "Feature",
                    //     geometry: geo,
                    // };           
                    // var fullFeature = geolayer.getSource().getFormat().readFeature(featurewrap, { featureProjection: "EPSG:3857" });
                    // geolayer.getSource().addFeature(fullFeature);
                
            }),
            style: (feature) => {
                return new OlStyle({
                    stroke: new OlStroke({
                        color: 'black',
                        width: 4
                    }),
                    zIndex: 0
                });
            }
        });
        curFeatlayer = new OlVectorLayer({
            source: new OlVectorSource({
                format: new OlGeoJSON(),
                loader: (extent, resolution, projection) => {         
                    
                    let featurewrap: any = {
                        type: "Feature",
                        geometry: geo,
                    };           
                    var fullFeature = curFeatlayer.getSource().getFormat().readFeature(featurewrap, { featureProjection: "EPSG:3857" });
                    curFeatlayer.getSource().addFeature(fullFeature);
                }
            }),
            style: (feature) => {
                return new OlStyle({
                    stroke: new OlStroke({
                        color: '#FF7B42',
                        width: 4,
                        lineDash:[6,6]
                    }),
                    zIndex: 0
                });
            }
        });
        layers.push(geolayer);
        layers.push(curFeatlayer);
        layers.push(selectlayer);
    }

    if (!map) {
        map = new OlMap({
            target: 'previewMap',
            layers: layers,
            view: new OlView({
                center: fromLonLat([6.747443, 49.981018]),
                maxZoom: 23,
                zoom: 15
            }),
            controls: defaults({ attribution: false, rotate: false, zoom: false })
        });
    }
    else {
        map.setLayers(layers);
    }
    if (geo) {
        geolayer.getSource().once('change', () => {
            map.getView().fit(selectlayer.getSource().getExtent(), { duration: 100, maxZoom: 21 });
        });
    }
    return map;


}


export { previewMap }